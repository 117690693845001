import React, { useEffect, useState } from 'react';
import { format, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, eachDayOfInterval, isSameMonth } from 'date-fns';
import { Box, Button, Typography } from '@mui/material';
const basePink = '#e7348e';
const blackColor = "#000000";
const daysInWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
// Helper function to get sales for a particular day (if any)
const getSalesForDate = (date, salesData) => {
    if (!date) {
        return 0;
    }
    return salesData[`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`] || 0;
};

// Helper function to calculate the color intensity based on sales value
const getSalesColor = (sales, maxSales) => {
    // const lightness = Math.max(minLightness, Math.min(maxLightness + (normalizedSales * (minLightness - maxLightness)), minLightness));
    const intensity = maxSales > 0 ? (sales / maxSales) * 100 : 0; // scale from 0 to 100


    // Define HSL values for your base pink (#e7348e)
    const h = 330;  // Hue for pink color
    const s = 80;   // Saturation (strong color)
    const l = 95 - intensity; // Lightness: lower intensity means darker color (lower lightness)
    // Return the color in HSL format
    return `hsl(${h}, ${s}%, ${l}%)`;
};

// Function to calculate luminance from an HSL color value
const getLuminance = (color) => {
    const hslMatch = color.match(/^hsl\((\d+), (\d+)%, (\d+)%\)$/);
    if (!hslMatch) return 0; // If the color is not in HSL format, return 0 luminance

    const [, h, s, l] = hslMatch.map(Number);

    // Convert HSL to RGB using a formula
    const hNormalized = h / 360;
    const sNormalized = s / 100;
    const lNormalized = l / 100;

    const a = sNormalized * Math.min(lNormalized, 1 - lNormalized);
    const f = (n) => {
        const k = (n + hNormalized) % 1;
        const color = 6 * k - 3;
        const rgb = a * Math.max(-1, Math.min(color, 4 - Math.abs(color), 1));
        return rgb + lNormalized;
    };

    const r = f(0);
    const g = f(8 / 12);
    const b = f(4 / 12);

    // Calculate the luminance using the standard formula
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance;
};
const getDayOfWeek = (date) => {
    const dayIndex = date.getDay();
    return daysInWeek[dayIndex];
};

// Function to calculate statistics based on day of the week
const calculateDayOfWeekStats = (salesData, customerCountData) => {
    const stats = {
        Sun: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
        Mon: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
        Tue: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
        Wed: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
        Thu: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
        Fri: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
        Sat: { totalSales: 0, totalCustomers: 0, count: 0, avgSales: 0 },
    };

    Object.keys(salesData).forEach((dateKey) => {
        const date = new Date(dateKey);
        const dayOfWeek = getDayOfWeek(date);

        stats[dayOfWeek].totalSales += salesData[dateKey];
        stats[dayOfWeek].totalCustomers += customerCountData[dateKey] || 0;
        stats[dayOfWeek].count += 1;
    });

    Object.keys(stats).forEach((day) => {
        const dayStats = stats[day];
        dayStats.avgSales = dayStats.count === 0 ? 0 : dayStats.totalSales / dayStats.count;
    });

    return stats;
};
const SalesCalendar = ({ salesData, customerCountData, month, year }) => {
    const [currentDate, setCurrentDate] = useState(new Date(year, month - 1));
    const [dayOfWeekStats, setDayOfWeekStats] = useState({});

    useEffect(() => {
        setCurrentDate(new Date(year, month - 1));
    }, [month, year])
    useEffect(() => {
        setCurrentDate(new Date(year, month - 1));
        const stats = calculateDayOfWeekStats(salesData, customerCountData);
        setDayOfWeekStats(stats);
    }, [month, year, salesData, customerCountData]);
    // Get all days in the current month, including overflow days from previous/next months
    const getDaysInMonth = (date) => {
        const startOfMonthDate = startOfMonth(date);
        const endOfMonthDate = endOfMonth(date);

        const startOfWeekDate = startOfWeek(startOfMonthDate);
        const endOfWeekDate = endOfWeek(endOfMonthDate);

        return eachDayOfInterval({ start: startOfWeekDate, end: endOfWeekDate });
    };

    // Find the maximum sales value for the current month to normalize the color scale
    const getMaxSales = () => {
        const salesValues = Object.values(salesData);
        return Math.max(...salesValues, 0); // Ensure no negative max sales
    };

    // Move to the next month
    const goToNextMonth = () => setCurrentDate(addMonths(currentDate, 1));

    // Move to the previous month
    const goToPrevMonth = () => setCurrentDate(addMonths(currentDate, -1));

    // Format the current month in a readable format (e.g., "January 2024")
    const getMonthYearLabel = () => format(currentDate, 'MMMM yyyy');

    const daysInMonth = getDaysInMonth(currentDate);
    const maxSales = getMaxSales();

    return (
        <Box sx={{ maxWidth: '600px', margin: '0 auto', padding: '20px', borderColor: "white" }}>
            {/* Calendar Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Typography variant="h6" color={"white"}>{getMonthYearLabel()}</Typography>
            </Box>

            {/* Calendar Body */}
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 1 }}>
                {/* Week Days Header */}
                {daysInWeek.map((day, index) => (
                    <Box key={index} sx={{ textAlign: 'center', fontWeight: 'bold', color: "white" }}>
                        <Typography variant="body2">{day}</Typography>
                    </Box>
                ))}

                {/* Calendar Days */}
                {
                    Object.keys(salesData).length === 0 && Object.keys(customerCountData).length === 0 ? <></> :
                        daysInMonth.map((date, index) => {
                            const isInCurrentMonth = isSameMonth(date, currentDate);
                            const sales = getSalesForDate(date, salesData);
                            const salesColor = getSalesColor(sales, maxSales);
                            const luminance = getLuminance(salesColor);
                            const customerCount = getSalesForDate(date, customerCountData);
                            // Determine text color: if luminance is low, use light text (white), else use dark text (black)
                            const textColor = luminance < 0.5 ? '#FFF' : '#000';

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: isInCurrentMonth ? salesColor : '#f2f2f2',
                                    }}
                                >

                                    <Typography variant="body1" sx={{ color: isInCurrentMonth ? textColor : '#9e9e9e' }}>
                                        {format(date, 'd')}
                                    </Typography>
                                    {isInCurrentMonth && sales > 0 && (
                                        <Typography variant="caption" sx={{ marginTop: 0.1, color: textColor }}>
                                            ₹{sales}
                                        </Typography>
                                    )}
                                    {isInCurrentMonth && customerCount > 0 && (
                                        <Typography variant="caption" sx={{ marginTop: 0.1, color: textColor }}>
                                            {customerCount}
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                {/* Weekday Stats */}
                {daysInWeek.map((day, index) => {
                    const stats = dayOfWeekStats[day] || { totalSales: 0, totalCustomers: 0, avgSales: 0 };
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f2f2f2',
                            }}
                        >
                            <Typography sx={{ marginTop: 0.1, color: blackColor, fontSize: '0.5rem' }}>
                                Sales:
                            </Typography>
                            <Typography sx={{ marginTop: 0.1, color: "#000000", fontSize: '0.5rem' }}>
                                ₹{stats.totalSales}
                            </Typography>

                        </Box>

                    );
                })}
                {daysInWeek.map((day, index) => {
                    const stats = dayOfWeekStats[day] || { totalSales: 0, totalCustomers: 0, avgSales: 0 };
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f2f2f2',
                            }}
                        >
                            <Typography sx={{ marginTop: 0.1, color: blackColor, fontSize: '0.5rem' }}>
                                Clients:
                            </Typography>
                            <Typography sx={{ marginTop: 0.1, color: blackColor, fontSize: '0.5rem' }}>
                                {stats.totalCustomers}
                            </Typography>
                        </Box>
                    );
                })}
                {daysInWeek.map((day, index) => {
                    const stats = dayOfWeekStats[day] || { totalSales: 0, totalCustomers: 0, avgSales: 0 };
                    // const isInCurrentMonth = isSameMonth(date, currentDate);
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f2f2f2',
                            }}
                        >

                            <Typography sx={{ marginTop: 0.1, color: blackColor, fontSize: '0.5rem' }}>
                                Avg Sales:
                            </Typography>

                            <Typography sx={{ marginTop: 0.1, color: blackColor, fontSize: '0.5rem' }}>
                                ₹{stats.avgSales.toFixed(2)}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>

        </Box>

    );
};

export default SalesCalendar;
